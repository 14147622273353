/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~tippy.js/dist/tippy.css';
@import '~tippy.js/themes/light.css';
@import '~tippy.js/animations/scale.css';
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
.display-flex {
    display: flex;
}
.relative{
    position: relative;
}
.fw-500{
    font-weight: 500;
}
.flex-1 {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3;
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.flex-6 {
    flex: 6;
}

[class*="flex-"] {
    padding-left: 10px;
}

[class*="flex-"]:first-child {
    padding-left: 0;
}

[class*="flex-"]:nth-last-child(2) {
    padding-right: 0;
}
.table-cell-link {
    // display: inline-block;
    width: fit-content;
    padding: 2px 10px;
    text-decoration: inherit;
    margin-left: -10px !important;
    &:hover {
        background: #ddd;
        border-radius: 6px;
    }
    
}
ion-content {
    --background: #eff6ff;
}

.custom-form-input-margin-top-10 {
    margin-top: 10px;
}

.icon-button-row {
    ion-button {
        height: 20px;
        margin: 0;
        margin-top: -12px;
    }
}
.overflow-button{
    --overflow: visible;
}

.title-content {
    ion-row {
        align-items: center;
    }
    .below-button-text {
        color: #666;
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
    }
    .title-text {
        display: flex;
        gap: 20px;
        &.column {
            flex-direction: column;
            justify-content: center;
            gap: 0;
        }

        h1 {
            font-weight: bold;
            font-size: 30px;
            margin: 0;
            margin-bottom: 5px;
            display: flex;
        }
        h4 {
            display: flex;
            align-items: center;
        }
    }
    .header-block-right{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }
}

.field-name {
    color: #666;
    font-size: 14px;
    padding-right: 10px;
    display: inline-block;
}
.title-content .field-name{
    min-width: 90px;
}
.field-value {
    color: #222;
    font-size: 14px;
}
.warning-card{
    text-align: center;
    margin-bottom: 20px;
    .text{
        border-radius: 10px;
        padding: 10px 20px;
        background: red;
        box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 5px -1px, rgba(0, 0, 0, 0.1) 0px 1px 3px -1px;
        color: white;
        display: inline-block;
        margin: 0;
    }
}
.detail-content {
    background: white;
    border-radius: 20px;
    font-size: 14px;
    position: relative;
    &:not(.wrapped){
        padding: 10px 20px;
        box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 5px -1px, rgba(0, 0, 0, 0.1) 0px 1px 3px -1px;
        margin-bottom: 20px;
    }
    .field-name {
        color: #666;
    }
    &.no-header {
        padding: 20px;
    }
    .section-title {
        color: #555;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        ion-icon {
            margin-left: 6px;
        }
    }
    .section-button {
        height: 18px;
    }

    .card-right-top-icon{
        --padding-end: 0;
        --padding-start: 0;
        width: 30px;
        height: 30px;
        background: var(--ion-color-danger);
        border-radius: 15px;
        position: absolute;
        right: -10px;
        top: -15px;
        z-index: 1;
        cursor: pointer;
        ion-icon{
            font-size: 18px;
        }
    }
}
.search-content {
    background: white;
    padding: 0;
    border-radius: 20px;
    box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 5px -1px, rgba(0, 0, 0, 0.1) 0px 1px 3px -1px;
    margin-bottom: 20px;
    .search-wrapper{
        padding: 5px 16px 5px 16px;
    }
    .filter-wrapper{
        padding: 5px 16px 5px 16px;
    }
    ion-searchbar {
        padding: 0;
        padding-top: 4px;
        --box-shadow: none;
        border-radius: 4px;
        --background: aliceblue;
        --border-radius: 5px;
        .searchbar-input-container input{
            white-space: pre-wrap;
            text-overflow: ellipsis;
            padding-inline-end: 86px;
        }
    }
    ion-label {
        font-weight: 500;
        color: #666;
    }
    .filters {
        height: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        ion-button {
            --border-width: 1px;
        }
    }
}
.table-content {
    background: white;
    border-radius: 20px;
    &:not(.wrapped){
        padding: 10px 0 20px;
        margin-bottom: 20px;
        box-shadow: rgba(50, 50, 93, 0.1) 0px 2px 5px -1px, rgba(0, 0, 0, 0.1) 0px 1px 3px -1px;
    }
    ion-grid {
        padding: 0;
        .title-row {
            background: aliceblue;
            padding: 0 20px;
            height: 40px;
            align-items: center;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            color: #444;
            margin-bottom: 5px;
            min-width: 960px;
            ion-col {
                align-items: center;
                display: flex;
                gap: 5px;
            }
            .title-row-checkbox{
                --min-height: 30px;
                --background: transparent;
                height: 30px;
                ion-label{
                    margin: 0 10px 0 0;
                    font-size: 13px;
                    color: var(--ion-color-primary);
                    text-transform: uppercase;
                    font-weight: 500;
                }
            }
        }
        .content-row,
        .final-row {
            padding: 0 20px;
            align-items: center;
            min-height: 30px;
            min-width: 960px;
            &.top-align {
                align-items: flex-start;
            }
            ion-col {
                font-size: 14px;
                color: #444;
                h3 {
                    margin: 0;
                    font-size: 14px;
                }
                p {
                    margin: 0;
                    &.faded {
                        color: #888;
                    }
                    &:has(> a) {
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .content-row.border-top {
            border-top: 1px solid #ccc;
        }
        .content-row:hover {
            background: #f5f5f5;
        }
        .final-row.border-top {
            border-top: 1px solid #ccc;
        }
        .detail-button-row {
            padding-right: 50px !important;
            position: relative;
            // &:hover {
            //     cursor: unset;
            // }
            .detail-button {
                position: absolute;
                right: 5px;
                height: 100%;
                display: flex;
                align-items: center;
                --padding-end: 0;
                --padding-start: 0;
                ion-icon{
                    font-size: 16px;
                }
                &.button-1{
                    right: 25px;
                }
            }
        }
    }
    &.no-bottom-padding {
        padding-bottom: 0;
    }
}
.table-content,
.detail-content {
    ion-grid {
        .header-row {
            align-items: center;
            padding: 0 20px 10px;
            .header-row-checkbox{
                --min-height: 30px;
            }
            h4 {
                margin: 0;
                color: #f06722;
                font-size: 15px;
                text-transform: uppercase;
                &.with-icon{
                    display: flex;
                    align-items: center;
                    ion-icon{
                        font-size: 18px;
                        margin-left: 8px;
                    }
                }
            }
            ion-button {
                height: 20px;
            }
            .header-text-right {
                color: #666;
                font-size: 14px;
                font-weight: 500;
            }
            &.border-bottom {
                border-bottom: 1px solid #ccc;
                padding-bottom: 0;
                margin-bottom: 10px;
            }
            ion-col:first-child {
                display: flex;
                align-items: center;
                gap: 20px;
            }
        }
        .title-row {
            background: aliceblue;
            padding: 0 20px;
            height: 40px;
            align-items: center;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 500;
            color: #444;
            margin-bottom: 5px;
            ion-col {
                align-items: center;
                display: flex;
                gap: 5px;
                .table-header-multiline-cell{
                    p{
                        margin: 0;
                        &.table-header-cell-subtitle{
                            margin: 0;
                            font-size: 11px;
                            margin-top: 4px;
                        }
                    }
                }
            }
            &.ion-no-padding{
                padding: 0;
            }
        }
        .sub-info {
            background: #f6f6f6;
            border: 1px solid #f4f4f4;
            margin-top: 10px;
            border-radius: 10px;
            padding: 0;
            min-height: 0;
            font-size: 14px;
            .sub-info-heading {
                font-weight: 500;
                color: #22a7f0;
            }
            ion-item {
                --min-height: 24px;
                --background: transparent;
                --padding-start: 0;
            }
        }
        .sub-info-button{
            position: absolute;
            right: 20px;
            z-index: 1;
        }
    }
}
.detail-content {
    ion-grid {
        .header-row {
            padding: 0 0 10px 0;
        }
    }
}
.split{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;
    .table-content, .detail-content{
        flex: 1;
    }

    .table-content {
        ion-grid {
            .title-row, .content-row {
                min-width: unset;
            }
        }
    }
}
.no-shadow{
    .table-content {
        box-shadow: none;
    }
}

.tab-row {
    align-items: center;
    ion-row {
        flex-wrap: nowrap;
        align-items: center;
    }
    ion-col {
        padding: 10px 10px;
        white-space: nowrap;
        cursor: pointer;
        color: #666;
        font-size: 0.9em;
        max-width: fit-content;
        &.selected {
            border: 1px solid #d7e8fe;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background: #d7e8fe;
            color: #111;
            font-size: 1em;
        }
        &:hover {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background: #e5eef9;
        }
    }
}
.component-loader-wrapper {
    position: relative;
    min-height: 200px;
}
.component-loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    background: rgba(255, 255, 255, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
    .spinner {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 3px solid #fff;
        border-top-color: #000;
        animation: spin 1s ease-in-out infinite;
    }
    @keyframes spin {
        to {
            transform: rotate(360deg);
        }
    }
}

.show-in-modal {
    display: none;
}
ion-button.hide-in-modal {
    display: inline-block;
}

.display-block {
    display: block;
}

ion-modal {
    ion-header.show-in-modal {
        display: block;
    }
    .hide-in-modal {
        display: none !important;
    }
    .title-content .title-text h4.hide-in-modal {
        display: none;
    }
}

/* hide scrollbar but allow scrolling */
.hide-scrollbar {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
}

.hide-scrollbar::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
}
.tab-scroll {
    overflow-x: scroll;
    &::-webkit-scrollbar-track {
        background: #ddd;
    }
    &::-webkit-scrollbar-thumb {
        background-color: #0c83ce; /* Set the color of the thumb */
    }
    &::-webkit-scrollbar {
        height: 8px; /* Set the width of the scrollbar */
        background-color: #f5f5f5; /* Set the background color of the scrollbar */
    }
}

.small-text {
    font-size: 0.8em;
}

.request-type-popover {
    --min-width: 400px;
}
ion-alert {
    &.custom-alert {
        --min-width: 400px;
        .danger {
            color: var(--ion-color-danger);
        }
        .primary {
            color: var(--ion-color-primary);
        }
        .medium {
            color: var(--ion-color-medium);
        }
    }
    &.select-alert{
        --max-width: 500px;
        .alert-wrapper .alert-radio-group button{
            display: contents;
        }
    }
    .alert-wrapper{
        .alert-message{
            white-space: pre-wrap;
        }
    }
}
.item-options-popover {
    --width: fit-content;
}
.hidden {
    display: none;
}
.opacity-0{
    opacity: 0;
}
a {
    text-decoration: none;
}
.in-page {
    .searchbar-clear-button.sc-ion-searchbar-md {
        right: 90px;
    }
}
pager {
    margin-top: 40px;
    display: block;
}
approval-inline {
    display: inline-block;
}
p.faded, li.faded, span.faded {
    color: #888;
}
p.small, li.small {
    font-size: 0.9em;
}
ion-toast.floating-1 {
    height: calc(100vh - 56px);
}
ion-toast.floating-2 {
    height: calc(100vh - 112px);
}
ion-toast.floating-3 {
    height: calc(100vh - 168px);
}

@-webkit-keyframes borderBlink {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: red;
    }
}
@keyframes borderBlink {
    from,
    to {
        border-color: transparent;
    }
    50% {
        border-color: red;
    }
}
.borderBlink {
    border: 1px solid red;
    -webkit-animation: borderBlink 1s step-end infinite;
    animation: borderBlink 1s step-end infinite;
}
.centered-svg {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 250px);
    gap: 20px;
    img {
        max-width: 200px;
    }
    p {
        color: #666;
    }
    li p{
        margin: 0;
    }
}
.menu-content-open {
    pointer-events: auto;
}
.text-selectable {
    user-select: text !important;
}

.clickable {
    cursor: pointer;
}
.always-clickable {
    cursor: pointer !important;
}
.link {
    color: var(--ion-color-primary);
}
.padding-right{
    padding-right: 16px;
}
.margin-right{
    margin-right: 16px;
}

.canceled *{
    text-decoration: line-through !important;
}
.capitalize{
    text-transform: lowercase;
    &:first-letter{
        text-transform: uppercase;
    }
}
input[readonly]:not([disabled]){
    color: #aaa !important;
}
ion-chip.ion-color-selected{
    background: #22a7f0;
    color: white;
    ion-label{
        color: white !important;
    }
}
ion-footer.data-add-button-footer{
    height: 44px;
    ion-toolbar{
        --min-height: 44px;
    }
}

.track-button{
    height: 30px;
}
.select-search-popover{
    &::part(content){
        --max-height: 400px;
        width: auto;
        max-width: 500px;
        min-width: 200px;
    }
    ion-content{
        --max-height: 400px;
        max-height: 290px;
    }
    .list-wrapper{
        overflow-y: auto;
        max-height: 290px;
    }
}
ion-row.border-bottom{
    border-bottom: 1px solid #ccc;
}
ion-row.border-top{
    border-top: 1px solid #ccc;
}   
.inline{
    display: inline-block;
}
.creation-link{
    display: inline-block;
    padding: 4px;
    margin-bottom: 5px;
    border-radius: 5px;
    font-weight: 500;
}
.line-item-add-button-div{
    align-items: center;
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    ion-icon{
        font-size: 20px;
    }
}
.content-wrapper{
    max-width: calc(100vw - 282px);
}
p.bold, span.bold{
    font-weight: 500;
}
.text-ellipsis{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.fixed{
    position: fixed;
}
.floating-header{
    position: fixed;
    ion-toolbar{
        --background: #eff6ff;
    }
    ion-title{
        p{
            font-size: 14px;
            font-weight: normal;
            color: var(--ion-color-primary);
        }
        p, h2{
            margin: 0;
        }
    }
}
.three-line-ellipsis{
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 0;
    margin: 5px;
}
.cursor-help{
    cursor: help;
}
@media print {
    .ion-page {
      display: initial !important;
      position: initial !important;
    }
    body {
      position: initial !important;
      max-height: initial !important;
      overflow: auto !important;
      print-color-adjust: exact;
    }
    ion-router-outlet {
      overflow: auto !important;
      contain: none !important;
      position: initial !important;
    }
    ion-content {
      --offset-bottom: unset !important;
      display: unset !important;
      position: unset !important;
    }
    .non-sidemenu-content{
      position: unset !important;
      overflow: auto !important;
      display: unset !important;
    }
    ion-split-pane{
      position: unset !important;
      overflow: auto !important;
      display: unset !important;
    }
    .pagebreak-after {
        break-after: page;
    }
    .pagebreak-before {
        break-before: page;
    }
}
.disabled-button-wrapper{
    display: inline-block;
    cursor: pointer !important;
}
.white-space-pre{
    white-space: pre;
}
#remarks-content{
    ion-textarea{
        .textarea-wrapper.sc-ion-textarea-md, .textarea-wrapper.sc-ion-textarea-ios{
            border: 1px solid lightgray;
        }
    }
}
.full-width{
    width: 100%;
}
ion-button.with-overlay{
    margin-top: 20px;
    --padding-start: 0;
    --padding-end: 0;
    .spinner-overlay {
        position: absolute;
        background: rgba(0, 0, 0, 0.75);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        ion-spinner {
            width: 20px;
        }
    }
    .button-text {
        padding: 0 16px;
        flex-direction: column;
        display: flex;
        justify-content: center;
    }
}
.ngx-pagination{
    padding-left: 0;
}
app-identifier{
    flex: 1;
    ion-col{
        justify-content: flex-end;
        display: flex;
        .identifier-input{
            display: flex;
            align-items: center;
            ion-label{
                text-align: right;
                padding-right: 5px;
            }
            ion-input{
                max-width: 100px;
                border: 1px solid #ccc;
                border-radius: 5px;
                height: 30px;
            }
        }
    }
}
.floating-banner{
    width: 100%;
    position: fixed;
    z-index: 2;
    display: flex;
    justify-content: center;
    .inner-div{
        padding: 10px;
        border-radius: 10px;
        text-align: center;
        position: fixed;
        &.danger{
            background: #f49f9f;
        }
        &.warning{
            background: #f4f4a1;
        }
    }
}

.border-right{
    border-right: 1px solid #ccc;
    &.faded{
        border-color: #f5f5f5;
    }
}
.border-left{
    border-left: 1px solid #ccc;
    &.faded{
        border-color: #f5f5f5;
    }
}
.border-top{
    border-top: 1px solid #ccc;
    &.faded{
        border-color: #f5f5f5;
    }
}

.formly-form-item-float-button{
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 10;
}
.pl-20{
    padding-left: 20px !important;
}
.pl-15{
    padding-left: 15px;
}
.pt-5{
    padding-top: 5px;
}
.pt-50{
    padding-top: 50px;
}

@media (min-width: 1201px) {
    .mobile-only{
        display: none !important;
    }
    .desktop-only{
        display: block;
    }

    // Modals
    .manage-mmids-modal::part(content) {
        width: 900px;
    }
    .create-mmid-modal {
        --height: 100vh;
        justify-content: flex-end;
        &::part(content) {
            width: 600px;
            box-shadow: -5px 0 20px rgba(0, 0, 0, 0.1333333333);
        }
    }
    .modal-1, .modal-3 {
        &.full-width {
            &::part(content) {
                width: 98vw;
            }
        }
        &.wide {
            &::part(content) {
                width: calc(100vw - 270px);
            }
        }
        &.medium {
            &::part(content) {
                width: calc(100vw - 450px);
            }
        }
        &.thin {
            &::part(content) {
                width: calc(100vw - 600px);
            }
        }
        ion-footer ion-toolbar{
            --background: #f0f6ff;
        }
    }
    .modal-1{
        --height: 90vh;
    }
    .modal-3{
        --height: 60vh;
    }
    .stock-checker-modal{
        --height: 100vh;
        justify-content: flex-end;
        &::part(content){
            width: 600px;
        }
    }
    .modal-2 {
        --height: 100vh;
        justify-content: flex-end;
        &.wide {
            &::part(content) {
                width: calc(100vw - 270px);
                box-shadow: -5px 0 20px rgba(0, 0, 0, 0.1333333333);
            }
        }
        &.medium {
            &::part(content) {
                width: calc(100vw - 450px);
                box-shadow: -5px 0 20px rgba(0, 0, 0, 0.1333333333);
            }
        }
        &.thin {
            &::part(content) {
                width: calc(100vw - 600px);
                box-shadow: -5px 0 20px rgba(0, 0, 0, 0.1333333333);
            }
        }
        .clickable {
            pointer-events: none;
        }
    }
    ion-modal.modal-default.inline-modal {
        --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
        --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;
    }

    ion-modal.modal-default.show-modal ~ ion-modal.modal-default{
        --backdrop-opacity: 0.32 !important;
    }
    ion-modal.modal-default.show-modal ~ ion-modal.modal-default::part(content){
        --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4) !important;
    }
    ion-modal.modal-default.show-modal ion-button.in-buttons.button-clear{
        &:hover{
            background: var(--ion-color-primary) !important;
        }
    }
}

@media (max-width: 1200px) {
    .mobile-only{
        display: block;
    }
    .desktop-only{
        display: none !important;
    }
    .table-content-wrapper{
        overflow-x: scroll;
    }
    .table-content,
    .detail-content {
        ion-grid {
            .header-row {
                ion-col:first-child {
                    display: block;
                }
            }
        }
    }
    app-select-search{
        width: 94vw;
        ion-title{
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.global-banner {
    position: absolute;
    top: 0;
    width: 700px;
    text-align: center;
    left: calc(50vw - 350px);
    .content {
        background: var(--ion-color-warning);
        display: inline-block;
        color: white;
        padding: 0 20px;
        border-radius: 8px;
        margin-top: 10px;
        p{
            margin: 5px;
            color: #222;
            font-weight: 500;
        }
    }
}
.blockquote{
    border-left: 4px solid #ccc;
    margin: 0;
    padding: 0 10px;
}

.sticky-toast{
    position: fixed;
    bottom: 10px;
    right: 20px;
    z-index: 9999;
    width: 300px;
    background: #3199db;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    box-shadow: 0 0 20px 1px #aaa;
    font-size: 14px;
}
.button-count{
    position: absolute;
    background: red;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -20px;
    top: -6px;
    font-size: 12px;
}
.ws-normal{
    white-space: normal;
}